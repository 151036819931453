export const SORTERS = {
  NUMBER_ASCENDING: mapper => (a, b) => (mapper(a) || 0) - (mapper(b) || 0),
  NUMBER_DESCENDING: mapper => (a, b) => (mapper(b) || 0) - (mapper(a) || 0),
  STRING_ASCENDING: mapper => (a, b) =>
    (mapper(a) || '').localeCompare(mapper(b) || ''),
  STRING_DESCENDING: mapper => (a, b) =>
    (mapper(b) || '').localeCompare(mapper(a) || ''),
};

export const getSorter = data => {
  const mapper = x => x[data.field];
  let sorter = SORTERS.STRING_ASCENDING(mapper);

  if (data.field === 'id') {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.NUMBER_ASCENDING(mapper)
        : SORTERS.NUMBER_DESCENDING(mapper);
  } else {
    sorter =
      data.direction === 'ascending'
        ? SORTERS.STRING_ASCENDING(mapper)
        : SORTERS.STRING_DESCENDING(mapper);
  }

  return sorter;
};

export function validateEmail(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email is not provided';
  } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/.test(values.email)) {
    errors.email = 'Use lowercase letters only';
  }
  return errors;
}

export function validateEmailAndId(values) {
  const errors = validateEmail(values);
  if (!values.id) {
    errors.id = 'Id is not provided';
  }
  return errors;
}
